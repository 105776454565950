export default (canvas, posicaoPlaca, caminhoImagem) => {
  const imagem = new Image()
  imagem.src = caminhoImagem

  const ctx = canvas.getContext('2d')

  const left = parseInt(posicaoPlaca.split(',')[0]) - 20
  const top = parseInt(posicaoPlaca.split(',')[1]) - 20
  const width = parseInt(posicaoPlaca.split(',')[2]) + 40
  const height = parseInt(posicaoPlaca.split(',')[3]) + 30

  canvas.height = height

  imagem.onload = () => {
    ctx.drawImage(imagem,
                  left, top,
                  width, height,
                  ((canvas.width - width) / 2), 0,
                  width, height,
    )
  }
}
